import axios from 'axios';
import Cookies from 'js-cookie';
import { BASE_URL } from 'common/constants';


export const requester = axios.create({ baseURL: BASE_URL });

const clearTokensAndRedirect = () => {
  Cookies.remove('access_token');
  Cookies.remove('refresh_token');
  window.location.href = '/sign-in';
};

const getLanguage = () => localStorage.getItem('lang') || 'ru';

requester.interceptors.request.use((config) => {
  config.headers[ 'Accept-Language' ] = getLanguage();

  const accessToken = Cookies.get('access_token');
  if (accessToken) {
    config.headers[ 'Authorization' ] = `Bearer ${accessToken}`;
  }
  return config;
});

requester.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const refreshToken = Cookies.get('refresh_token');
      if (refreshToken) {
        try {
          const { data } = await requester.post('/api/v1/auth/refresh/', { refresh: refreshToken });
          const newAccessToken = data.access;
          Cookies.set('access_token', newAccessToken, {
            expires: 7,
            sameSite: 'Strict',
          });
          originalRequest.headers[ 'Authorization' ] = `Bearer ${newAccessToken}`;
          return requester(originalRequest);
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
          clearTokensAndRedirect();
        }
      } else {
        console.error('No refresh token available.');
        clearTokensAndRedirect();
      }
    }
    return Promise.reject(error);
  }
);
