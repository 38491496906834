import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { generateRel } from 'common/helpers';
import classes from './CustomButton.module.scss';


const CustomButton = ({
  text,
  theme,
  isArrow,
  path = '',
  isRounded,
  outlined,
  MyClass,
  disabled = false,
  ...props
}) => {
  const themes = {
    yellow: 'yellow',
    white: 'white',
    transparent: 'transparent',
    dark: 'dark',
    red: 'red',
    disabled: 'disabled'
  };

  const theTheme = () => {
    switch (theme) {
    case themes.yellow:
      return classes.btnYellowTheme;
    case themes.white:
      return classes.btnWhiteTheme;
    case themes.transparent:
      return classes.btnTransparentTheme;
    case themes.dark:
      return classes.btnDarkTheme;
    case themes.red:
      return classes.btnRedTheme;
    case themes.disabled:
      return classes.btnDisabledTheme;
    default:
      return classes.btnYellowTheme;
    }
  };

  const rounded = isRounded ? classes.rounded : '';
  const isOutlined = outlined ? classes.outlined : '';

  const options = {
    disableRipple: true,
    color: 'info',
    component: path ? 'a' : 'button',
    target: path ? '_blank' : '',
    ...(path ? { href: path, ...generateRel(path) } : {}),
  };

  return (
    <Button
      className={`${classes.btn} ${theTheme()} ${rounded} ${isOutlined} ${MyClass}`}
      disabled={disabled}
      {...props}
      {...options}
    >
      <span className={classes.btnText}>{text}</span>
      {!!isArrow && <ArrowForwardIcon />}
    </Button>
  );
};

export default CustomButton;
